import './assets/main.css'

import {createApp} from 'vue'
import {createRouter, createWebHashHistory} from "vue-router";
import store from './store';
import App from './App.vue'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import dashboard from "@/components/dashboard.vue"
import shelters from "@/components/shelters.vue"
import rrequests from "@/components/rrequests.vue"
import incidents from "@/components/incidents.vue"
import onlineUsers from "@/components/onlineUsers.vue"
import events from "@/components/events.vue"
import fileRepository from "@/components/fileRepository.vue"
import useradmin from "@/components/useradmin.vue"
import oem_main from "@/components/oem_main.vue"
import loginFormDialogue from "@/components/loginFormDialogue.vue";
import {initializeApp} from 'firebase/app';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import addNewIncidentDialog from "@/components/addNewIncidentDialog.vue";
import addNewShelter from "@/components/addNewShelter.vue";
import addNewResReq from "@/components/addNewResReq.vue";
import eventdb from "@/components/eventdb.vue";
import db911 from "@/components/911db.vue";
import {apiClient, apiClientSimple, getUserData, initializeDataLoad} from "@/services.js";
import {checkAuthState} from "@/auth.js";
import iicepStore from "@/iicepStore.js";

if (typeof global === 'undefined') {
    window.global = window;
}

let app;
const firebaseConfig = {
    apiKey: "AIzaSyBtZVZPr0TNn0jvZ_Yfh9ZvQZaUCQm5VSc",
    authDomain: "iicep-e8fc5.firebaseapp.com",
    databaseURL: "https://iicep-e8fc5-default-rtdb.firebaseio.com",
    projectId: "iicep-e8fc5",
    storageBucket: "iicep-e8fc5.appspot.com",
    messagingSenderId: "935109385449",
    appId: "1:935109385449:web:ee4a82da7728c4ed46a478",
    measurementId: "G-P1G09RZ1BW"
};
const vl = {'ACTIVE':'', 'DEMO': 'Practice&Exercise'};

export const firebaseApp = initializeApp(firebaseConfig);
export const authApp = getAuth();

const vuetify = createVuetify({
    icons: {
        iconfont: 'mdi',
    }, components, directives,
});

const config = {
    $interval: '',
    $user: {},
    $backendUrl: 'https://iicep.com/',
    $repoTypes: [{ l: 'SOP', v: 1 },
        { l: 'EOP', v: 2 },
        { l: 'IAP', v: 3 },
        { l: 'Files', v: 4 },
        { l: 'LE', v: 5 }],
    $yesno: [{'v':0,'l':'No'},
        {'v':1,'l':'Yes'}],
    $rrNeedTypes: [
        {'v':0, 'l':'Equipment'},
        {'v':1, 'l':'Personnel'},
        {'v':2, 'l':'Supplies'},
        {'v':3, 'l':'Other need'}],
    $rrSeverity: [
        {'v':0, 'l':'Not Set'},
        {'v':1, 'l':'Low'},
        {'v':2, 'l':'Medium'},
        {'v':3, 'l':'High'}],
    $versionLabels: vl,
    $rolelevels: [
        'A', // idx=0, role ESF-1
        'A', // idx=1, role ESF-2
        'A', // idx=2, role ESF-3
        'B', // idx=3, role ESF-4
        'C', // idx=4, role ESF-5
        'A', // idx=5, role ESF-6
        'A', // idx=6, role ESF-7
        'A', // idx=7, role ESF-8
        'A', // idx=8, role ESF-9
        'A', // idx=9, role ESF-10
        'A', // idx=10, role ESF-11
        'A', // idx=11, role ESF-12
        'D', // idx=12, role ESF-13
        'A', // idx=13, role ESF-14
        'A', // idx=14, role ESF-15
        'F', // idx=15, role Custom
        'G', // idx=16, role Admin
        'E',  // idx=17, role Empty
        'H'  // idx=18, Role ESF-2a911
    ],
    $needLabels: [
        'Operators Needed:',
        'Skills Needed:',
        'Supplies Needed:',
        'Other Needs:'
    ],
    $ics214roles: [
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'}, // id 0 ESF-1
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'}, // id 1 ESF-2
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'},  // id 2 ESF-3
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'}, {'v':3,'l':'ESF-4 Firefighting'},  // id 3 ESF-4
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':12,'l':'ESF-13 Public Safety'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'}, {'v':3,'l':'ESF-4 Firefighting'},  // id 4 ESF-5
            {'v':4,'l':'ESF-5 Emergency Management'}, {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'},
            {'v':7,'l':'ESF-8 Public Health'}, {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'},
            {'v':10,'l':'ESF-11 Agriculture/Natural Resources'}, {'v':11,'l':'ESF-12 Energy'}, {'v':12,'l':'ESF-13 Public Safety'},
            {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'},  // id 5 ESF-6
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'},  // id 6 ESF-7
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'},  // id 7 ESF-8
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'},  // id 8 ESF-9
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'},  // id 9 ESF-10
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'},  // id 10 ESF-11
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'},  // id 11 ESF-12
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'},  // id 12 ESF-13
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':12,'l':'ESF-13 Public Safety'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'},
            {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'},  // id 13 ESF-14
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'},  // id 14 ESF-15
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'},  // id 15 Custom (like ESF-1)
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'}, {'v':3,'l':'ESF-4 Firefighting'},  // id 16 Admin
            {'v':4,'l':'ESF-5 Emergency Management'}, {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'},
            {'v':7,'l':'ESF-8 Public Health'}, {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'},
            {'v':10,'l':'ESF-11 Agriculture/Natural Resources'}, {'v':11,'l':'ESF-12 Energy'}, {'v':12,'l':'ESF-13 Public Safety'},
            {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
        [], // id 17 Empty
        [{'v':0,'l':'ESF-1 Transportation'}, {'v':1,'l':'ESF-2 Communications'}, {'v':2,'l':'ESF-3 Public Works/Eng'}, // id 18 ESF-2a911
            {'v':5,'l':'ESF-6 Mass Care/Human Services'}, {'v':6,'l':'ESF-7 Logistics/Res Support'}, {'v':7,'l':'ESF-8 Public Health'},
            {'v':8,'l':'ESF-9 Search and Rescue'}, {'v':9,'l':'ESF-10 Hazardous Materials'}, {'v':10,'l':'ESF-11 Agriculture/Natural Resources'},
            {'v':11,'l':'ESF-12 Energy'}, {'v':13,'l':'ESF-14 Long Term Community Recovery'}, {'v':14,'l':'ESF-15 External Affairs/PIO'}],
    ]
};
const routes = [
   {path: '/', component: dashboard, name: 'home', meta: {requiresAuth: true, hdr: false, mapSidePanel: false}
}, {path: '/oem',
    component: oem_main,
    name: 'oemmain',
    meta: {requiresAuth: true, hdr: true, mapSidePanel: false},
    children: [
          {path: 'shelters', component: shelters, name: 'shelters', meta: {requiresAuth: true, hdr: true, mapSidePanel: true}
        },{path: 'resource-requests', component: rrequests, name: 'resreqs', meta: {requiresAuth: true, hdr: true, mapSidePanel: true}
        },{path: 'incidents', component: incidents, name: 'incidents', meta: {requiresAuth: true, hdr: true, mapSidePanel: true}
        },{path: 'newincident', component: addNewIncidentDialog, name: 'newincident', meta: {requiresAuth: true, hdr: true, mapSidePanel: true}
        },{path: 'newcenter',
            component: addNewShelter,
            name: 'newcenter',
            meta: {requiresAuth: true, hdr: true, mapSidePanel: true}
        },{path: 'new-resource-request',
            component: addNewResReq,
            name: 'newresreq',
            meta: {requiresAuth: true, hdr: true, mapSidePanel: true}
        },
        {path: 'events', component: events, name: 'events', meta: {requiresAuth: true, hdr: true, mapSidePanel: true}},
        {path: 'online', component: onlineUsers, name: 'onlineusers', meta: {requiresAuth: true, hdr: true, mapSidePanel: true}}
    ]
}, {path: '/login', component: loginFormDialogue, name: 'login_form', meta: {hdr: true, mapSidePanel: false}
}, {path: '/fileRepository',
    component: fileRepository,
    name: 'fileRepository',
    meta: {requiresAuth: true, hdr: true, mapSidePanel: false}
}, {path: '/911',
    component: db911,
    name: 'db911',
    meta: {requiresAuth: true, hdr: true, mapSidePanel: false}
}, {path: '/eventdb',
    component: eventdb,
    name: 'eventdb',
    meta: {requiresAuth: true, hdr: true, mapSidePanel: false}
}, {path: '/administration', component: useradmin, name: 'useradmin', meta: {requiresAuth: true, hdr: true, mapSidePanel: false}
}];
const router = createRouter({
    history: createWebHashHistory(), routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
    const isLoggedIn = store.getters.isLoggedUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const loginPath = '/login';
    if (!!to.meta.mapSidePanel) {
        store.commit('setCollapsed', false);
    }
    if (requiresAuth && !isLoggedIn) {
        store.commit('setIntendedDestination', to.fullPath);
        next('/login'); // Redirect to login page if user is not logged in and route requires authentication
    } else {
        if ((from.path === loginPath || from.name === loginPath) && from.path === to.path) {
            store.commit('setIntendedDestination', '/');
            next();
        } else if ((to.path === loginPath || to.name === loginPath) && isLoggedIn) {
            next('/');
        } else {
            next();
        }
    }
})


checkAuthState().then(async (user,idToken) => {
    try {
        let versLabel = '';
        if (user) {
            localStorage.setItem('jwtToken', idToken);
            if (localStorage.getItem('logged') === '1' && !!localStorage.getItem('umail')) {
                const resp = await apiClientSimple.get('auth_.php', {params: {'email': localStorage.getItem('umail')}});
                if (resp.data.status ==='OK' && resp.data.data) {
                    let userObj = resp.data.data;
                    store.commit('setLoggedUserObj',userObj);
                    store.commit('setICSEvent',localStorage.getItem('ICSEventID'));
                    const intRole = parseInt(userObj['ROLE']);
                    store.commit('setUserESF', intRole);
                    store.commit('setAccessLevel',config.$rolelevels[intRole]);
                    store.commit('setUserRoles', config.$ics214roles[intRole]);
                    store.commit('setVersionLabel', vl[userObj['MODE']]);
                    initializeDataLoad();
                }
            }
        }
        const app = createApp(App);
        app.config.globalProperties = config;
        app.config.globalProperties.$user = user;
        app.use(store)
            .use(router)
            .use(vuetify)
            .mount('#app');
    } catch (error) {
        console.error('Failed to check auth state:', error);
    }
}).catch(error => {
    console.error('Failed to check auth state:', error);
});

