import axios from "axios";
import store from '@/store';
import {authApp} from "@/main.js";
import iicepStore from "@/iicepStore.js";

export async function uploadAttachment(filedata) {
    const tkn = store.getters.getBToken;
    const formData = new FormData();
    formData.append("ufile", filedata);
    formData.append("token", tkn);
    const headers = {
        'Content-Type': 'multipart/form-data'
    };
    try {
      const response = await axios.post(
          './upload.php',
          formData,
          {
              headers: headers
          })
          return response.data;
    } catch (error) {
      throw error;
    }
}
export async function uploadAttachmentName(filedata) {
    const formData = new FormData();
    formData.append("ufile", filedata);
    try {
        const response = await apiClientSimple.post('uploadr.php',formData);
        if (response.data['Status']==='OK') {
            return response.data.newname ;
        } else {
            console.log('error uploading document');
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}
export async function uploadAttachmentToSitrep(filedata) {
    const formData = new FormData();
    formData.append("ufile", filedata);
    try {
        const response = await apiClientSimple.post('uploadToSitrep.php',formData);
        if (response.data['Status']==='OK') {
            return response.data['ID'] ;
        } else {
            console.log('error uploading document');
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}
export async function createIncident(incdata) {
    const tkn = store.getters.getBToken;
    const formData = new FormData();
    Object.keys(incdata).forEach(k =>{
        formData.append(k, incdata[k]);
    });
    formData.append("token", tkn );
    const headers = {
        'Content-Type': 'multipart/form-data'
    };
    formData.append('mode', 'addinc');
    axios.post(
        './savedata.php',
        formData,
        {
            headers: headers
        })
        .then(response => {
            console.log(response.data);
        })
        .catch(error => {
            console.error('Token verification failed:', error);
        });
}
export async function deleteShelter(cid) {
    const tkn = store.getters.getBToken;
    const formData = new FormData();
    formData.append('mode', 'deleteemcen');
    formData.append('id', cid);
    formData.append("token", tkn );
    const headers = {
        'Content-Type': 'multipart/form-data'
    };
    axios.post(
        './savedata.php',
        formData,
        {
            headers: headers
        })
        .then(response => {
            console.log(response.data);
        })
        .catch(error => {
            console.error('Token verification failed:', error);
        });
}
export async function createShelter(shdata) {
    const tkn = store.getters.getBToken;
    const center = store.getters.getNewCenter;
    let mode = '';
    switch (center.type) {
        case 'shelter':
            mode = 'addshelt';
            break;
        case 'decon':
            mode = 'adddecon';
            break;
        case 'recept':
            mode = 'addrecept';
            break;
        default:
            break;
    }
    const formData = new FormData();
    Object.keys(shdata).forEach(k =>{
        if (k!=='type') {
            formData.append(k, shdata[k]);
        }
    });
    formData.append("token", tkn );
    const headers = {
        'Content-Type': 'multipart/form-data'
    };
    formData.append('mode', mode);
    axios.post(
        './savedata.php',
        formData,
        {
            headers: headers
        })
        .then(response => {
            console.log(response.data);
        })
        .catch(error => {
            console.error('Token verification failed:', error);
        });
}
export async function geocodeAddress(addresstxt) {
    try {
        const tkn = store.getters.getBToken;
        const formData = new FormData();
        formData.append("token", tkn );
        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        formData.append('mode', 'geocode');
        formData.append('address', addresstxt);
        let georesp = await axios.post(
            './getcoords.php',
            formData,
            {
                headers: headers
            })
        return georesp.data;
    } catch (error) {
        console.log('C');
        throw error;
    }
}
export async function saveData(formdata) {
    try {
        const response = await apiClientSimple.post('./saveData.php',formdata);
        return response.data;
    } catch (error) {
        throw error;
    }
}
export async function saveIap(formdata) {
    try {
        const response = await apiClientSimple.post('./saveiap.php',formdata);
        return response.data;
    } catch (error) {
        throw error;
    }
}
export async function save911(formdata) {
    try {
        const response = await apiClientSimple.post('./save911.php',formdata);
        return response.data;
    } catch (error) {
        throw error;
    }
}
export async function saveAnno(formdata) {
    try {
        const response = await apiClientSimple.post('./saveData.php',formdata);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function getLogs(params){
    try {
        const response = await apiClientSimple.get('./getlogs.php', {params: params})
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function getICS214roster(eid) {
    const params = {'mode':'dutylog', 'eid': eid}
    try {
        const response = await apiClientSimple.get('./getData.php', {params: params})
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function getIap(iapid) {
    const params = {'iapInc':iapid}
    try {
        const response = await apiClientSimple.get('./getiap.php', {params: params})
        if (response.data.Status==='OK')
            return response.data.data;
        else return {};
    } catch (error) {
        throw error;
    }
}

export async function getIncHistory(iid) {
    const params = {'mode':'inchistory', 'id': iid}
    try {
        const response = await apiClientSimple.get('./getData.php', {params: params})
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function resetPwd(email) {
    const params = {'email': email}
    try {
        const response = await axios.get('https://iicep.com/resetpwd.php', {params: params})
        return response.data;
    } catch (error) {
        return {'Status':'Error resetting password.'};
    }
}

export async function getLogs_(formdata) {
    const tkn = store.getters.getBToken;
    formdata.append("token", tkn);
    const headers = {
        'Content-Type': 'multipart/form-data'
    };
    try {
        const response = await axios.post(
            './getlogs.php',
            formdata,
            {
                headers: headers
            })
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const apiClientForm = axios.create({
    baseURL: iicepStore.data.backendUrl,
    withCredentials: true
});

export const apiClientSimple = axios.create({
    baseURL: iicepStore.data.backendUrl,
    withCredentials: true
});

export const apiClient = axios.create({
    baseURL: iicepStore.data.backendUrl,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true
});

apiClient.interceptors.request.use(async config => {
    const user = authApp.currentUser;
    if (user) {
        const token = await user.getIdToken();
        localStorage.setItem('jwtToken', token);
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

apiClientForm.interceptors.request.use(async config => {
    const user = authApp.currentUser;
    if (user) {
        const token = await user.getIdToken();
        localStorage.setItem('jwtToken', token);
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export async function getUserData() {
    try {
      const email = localStorage.getItem('umail');
      const response = await apiClient.get('/getUDef2024.php',{ params: {'email': email}});
      if (response.data.status ==='OK') {
          return response;
      } else {
          console.error("Error fetching user data: ");
          return null;
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
      return null;
    }
}

export function objectToFormData(obj) {
    const formData = new FormData();
    Object.keys(obj).forEach(key => formData.append(key, obj[key]));
    return formData;
}

export function initializeDataLoad() {
    if (iicepStore.data.iicepInterval !== '') {
        clearInterval(iicepStore.data.iicepInterval);
        iicepStore.data.iicepInterval = '';
    }
    store.dispatch('fetchDefinitions');
    store.dispatch('fetchData');
    iicepStore.data.iicepInterval = setInterval(refreshData,60000);
}

function refreshData() {
    store.dispatch('fetchData');
}

export async function getALog(uid,eid) {
    try {
        const response = await apiClientSimple.get('/getUserLog.php',{params: {'uid': uid,'eid':eid}});
        if (response.data.Status ==='OK') {
            return response.data;
        } else {
            console.error("Error fetching user data: ");
            return null;
        }
    } catch (error) {
        console.error("Error fetching user data: ", error);
        return null;
    }

}

export async function getEocAf(mcode,eventid) {
    try {
        const response = await apiClientSimple.get('/getaf.php',{ params: {'mcode': mcode, 'event': eventid}});
        if (response.data.Status ==='OK') {
            return response.data;
        } else {
            console.error("Error fetching user data: ");
            return null;
        }
    } catch (error) {
        console.error("Error fetching user data: ", error);
        return null;
    }
}
export async function saveInc911(formdata) {
    try {
        const response = await apiClientSimple.post('./setData911.php',formdata);
        return response.data;
    } catch (error) {
        throw error;
    }
}
