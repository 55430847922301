<script>
export default {
  methods: {},
  data() {
    return {
      dialog: false,
      eventName: 'TestEvent'
    }
  }
}
</script>
<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="80vw"
  >
    <template v-slot:activator="{ props }">
      <v-btn
          density="compact"
          icon="mdi-file-cabinet"
          size="small"
          v-bind="props"
          variant="flat"
      >
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-left ma-3">
        <span class="text-h5"> Event {{ eventName }} SitRep Revision #1</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="pa-1">
            <v-row>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="Event name"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-autocomplete
                    :items="['Not Set','Major Assistance Required','Under Control','Resolved','Unknown', 'Closed']"
                    density="compact"
                    label="Resolution Status"
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="Event Status"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-autocomplete
                    :items="['Not Set','Major','Moderate','Minor','Unknown']"
                    density="compact"
                    label="Event Severity"
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mb-1">
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-autocomplete
                    :items="['Not Set','Major Assistance Required','Under Control','Resolved','Unknown', 'Closed']"
                    density="compact"
                    label="Related events"
                    multiple
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="SCOEM"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-1 pt-1 pb-2"
                  cols="12"
              >
                <v-text-field
                    density="compact"
                    label="Scrolling Message"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pb-0">
              <v-col
                  class="pa-1 pt-0 pb-0 "
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="Wind Direction "
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0 mb-0 mt-0"
                  cols="6"
              >
                <v-autocomplete
                    :items="['Not Set','Notification of Unusual Event','Alert','Site Area Emergency','General Emergency']"
                    density="compact"
                    label="Classification"
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="pt-0 pb-0">
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="Wind Speed"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="Condition"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col
                  class="mt-2 pa-1 pt-0  pb-0 text-center"
                  cols="4"
              >
                <span class="text-center font-weight-bold ">Spill type: </span>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="4"
              >
                <v-checkbox
                    id="checkbox3"
                    density="compact"
                    hide-details
                    label="Large Spill"
                    value="Large Spill"
                >
                </v-checkbox>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="4"
              >
                <v-checkbox
                    id="checkbox3"
                    density="compact"
                    hide-details
                    label="Daytime"
                    value="Daytime"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-1 pt-1 pb-2"
                  cols="12"
              >
                <v-textarea
                    auto-grow
                    class="mt-0"
                    density="compact"
                    label="Situation brief"
                    required
                    rows="1"
                    variant="outlined"
                ></v-textarea>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-2 mt-0 mb-3"
                  cols="12"
              >
              </v-col>
            </v-row>
            <v-row class="pt-2 pb-0 mt-2">
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="EOC 24/7 Phone"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="Phone extension"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-2">
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-autocomplete
                    :items="['Full', 'Partial']"
                    density="compact"
                    label="Activation status"
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-text-field
                    density="compact"
                    label="Address of Command Post"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-0">
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-autocomplete
                    :items="['Yes', 'No']"
                    density="compact"
                    label="Emergency declaration"
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pa-1 pt-0 pb-0"
                  cols="6"
              >
                <v-file-input
                    append-inner-icon="mdi-paperclip"
                    clearable
                    density="compact"
                    label="Upload file"
                    prepend-icon=""
                    variant="outlined"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
          <v-col
              class="pa-1 pt-0"
              cols="6"
          >
            <v-row>
              <v-col
                  class="d-flex flex-row pa-1 pt-0"
                  cols="6"
              >
                <v-btn
                    class=" ma-1 ml-5 mr-3 "
                    density="comfortable"
                    size="default"
                    variant="outlined"
                >IAP Quick start form
                </v-btn>
              </v-col>
              <v-col
                  class="d-flex flex-row-reverse pa-1 pt-0"
                  cols="6"
              >
                <v-btn
                    class=" ma-1 ml-1 "
                    density="comfortable"
                    size="default"
                    variant="outlined"
                >All Hazard SitRep
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="mt-2 pa-1 pt-0  pb-0 text-center"
                  cols="12"
              >
                <span class="text-left font-weight-bold">Essential functions: </span>
                <span
                    class="text-left">Check all Essential functions considered fully operational for this Event: </span>
              </v-col>
            </v-row>
            <v-row class="ml-4 mr-0 mb-0">
              <v-col
                  class="d-flex flex-wrap pa-1 pt-0 pb-0"
                  cols="12"
              >
                <v-checkbox
                    id="checkbox3"
                    density="compact"
                    hide-details
                    label="COG"
                    value="COG"
                >
                </v-checkbox>
                <v-checkbox
                    id="checkbox3"
                    density="compact"
                    hide-details
                    label="Power"
                    value="Power"
                >
                </v-checkbox>
                <v-checkbox
                    id="checkbox3"
                    density="compact"
                    hide-details
                    label="LE"
                    value="LE"
                >
                </v-checkbox>
                <v-checkbox
                    id="checkbox3"
                    density="compact"
                    hide-details
                    label="Fuel"
                    value="Fuel"
                >
                </v-checkbox>
                <v-checkbox
                    id="checkbox3"
                    density="compact"
                    hide-details
                    label="Fire"
                    value="Fire"
                >
                </v-checkbox>
                <v-checkbox
                    id="checkbox3"
                    density="compact"
                    hide-details
                    label="Water"
                    value="Water"
                >
                </v-checkbox>
                <v-checkbox
                    id="checkbox3"
                    density="compact"
                    hide-details
                    label="EMS"
                    value="EMS"
                >
                </v-checkbox>
                <v-checkbox
                    id="checkbox3"
                    density="compact"
                    hide-details
                    label="Sewer"
                    value="Sewer"
                >
                </v-checkbox>
                <v-checkbox
                    id="checkbox3"
                    density="compact"
                    hide-details
                    label="MED"
                    value="MED"
                >
                </v-checkbox>
                <v-checkbox
                    id="checkbox3"
                    density="compact"
                    hide-details
                    label="PW"
                    value="PW"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pt-0 pb-0 ma-2 mb-2 "
                  cols="12"
              >
                <v-textarea
                    auto-grow
                    class="mt-0"
                    density="compact"
                    label="County/Municipal issues"
                    required
                    rows="2"
                    variant="outlined"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-2 mr-0">
              <v-col
                  class="pt-0 pr-0 mt-0 mb-0 ma-2"
                  cols="6"
              >
                <v-textarea
                    auto-grow
                    class="mt-0 pr-0"
                    density="compact"
                    label="Public Transportation"
                    required
                    rows="1"
                    variant="outlined"
                ></v-textarea>
              </v-col>
              <v-col
                  class="pt-0 pr-0 mt-0 mr-0 ma-2"
                  cols="5"
              >
                <v-text-field
                    class="pl-0 ml-0 mr-0 pr-0"
                    density="compact"
                    label="Date"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col
                  class="pt-0 pr-0 pb-0 ml-2"
                  cols="6"
              >
                <v-autocomplete
                    :items="['Voluntary','Mandatory']"
                    density="compact"
                    label="Evacuation Type"
                    multiple=""
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pt-0 pr-0 mt-0 mr-0 ma-2"
                  cols="5"
              >
                <v-text-field
                    density="compact"
                    label="Date"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col
                  class="pt-0 pr-0 pb-0 ml-2 mt-0"
                  cols="6"
              >
                <v-autocomplete
                    :items="['Yes','No']"
                    density="compact"
                    label="Shelter-In-Place"
                    multiple=""
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pt-0 pb-0 pr-0 mt-0 mr-0 ma-2"
                  cols="5"
              >
                <v-text-field
                    density="compact"
                    label="Date"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col
                  class="pt-0 pb-0 ma-2 mb-2"
                  cols="12"
              >
                <v-textarea
                    auto-grow
                    class="mt-0"
                    density="compact"
                    label="Situation brief"
                    required
                    rows="1"
                    variant="outlined"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col
                  class="pt-0 pb-0 ml-2"
                  cols="12"
              >
                <v-autocomplete
                    :items="['Yes','No']"
                    density="compact"
                    label="Open Shelters"
                    multiple=""
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col
                  class="pt-0 pb-0 ml-2"
                  cols="12"
              >
                <v-autocomplete
                    :items="['Yes','No']"
                    density="compact"
                    label="Open Decon Centers"
                    multiple=""
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col
                  class="pt-0 pb-0 ml-2"
                  cols="12"
              >
                <v-autocomplete
                    :items="['Yes','No']"
                    density="compact"
                    label="Open Reception Centers"
                    multiple=""
                    variant="outlined"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
            class="ma-1 ml-0"
            size="default"
            variant="outlined"
            @click="dialog = false"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            class="ma-1 mr-0"
            size="default"
            variant="outlined"
            @click="dialog=false"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
</style>