<script setup>
import {db, auth} from '@/firebase/init.js';
import {query, collection, addDoc, setDoc, getDocs, deleteDoc, where } from 'firebase/firestore';
import mapwrk from "@/components/mapwrk.vue";
import { defaultWorkerItem, defaultEmWorkerItem }from "@/objTemplates.js"

</script>
<template>
  <v-dialog
      id="emergency_workers"
      v-model="dialog"
      :width="dlgwid"
      persistent
      theme="light"
  >
    <template v-slot:activator="{ props }">
      <v-btn
          density="comfortable"
          v-bind="props"
          variant="outlined"
      >Emergency workers
      </v-btn>
    </template>
    <v-card
        height="90vh"
        theme="light">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="6">
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn
                    :variant="DWvisible?'flat':'outlined'"
                    color="blue-darken-1"
                    class="my-2 mx-1"
                    @click="showDW"
                >
                  Dosimetry workers
                </v-btn>
                <v-btn
                    :variant="EWvisible?'flat':'outlined'"
                    color="blue-darken-1"
                    class="my-2 mx-1"
                    @click="showEW"
                >
                  Emergency workers
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="pa-2">
                <v-data-table
                    v-show="DWvisible"
                    items-per-page="5"
                    :items-per-page-options="[5]"
                    :headers="headers1"
                    :items="workers"
                    :search="search_d"
                    class="elevation-1"
                    item-value="name"
                    density="compact"
                >
                  <template v-slot:top>
                    <v-toolbar color="white" flat>
                      <div class="d-flex w-100 align-center">
                        <v-text-field
                            v-model="search_d"
                            append-icon="mdi-magnify"
                            density="compact"
                            hide-details="true"
                            label="Search"
                            single-line variant="outlined">
                        </v-text-field>
                        <v-btn
                            size="small"
                            class="ml-2"
                            color="blue-darken-1"
                            variant="elevated"
                            @click="addNew">
                          Add worker
                        </v-btn>
                      </div>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                        class="mr-2 my-1"
                        size="small"
                        color="orange-accent-1"
                        variant="elevated"
                        @click="viewEdit(item.worker_id)"
                    >View and Edit
                    </v-btn>
                    <v-btn
                        size="small"
                        color="red-darken-1"
                        @click="deleteItem = item"
                    >
                      Delete
                      <v-dialog
                          v-model="deleteDialog"
                          activator="parent"
                          width="auto"
                      >
                        <v-card>
                          <v-card-text>
                            Worker {{ deleteItem.name }} will be deleted
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                                size="small"
                                color="red-accent-2"
                                @click="deleteDialog = false;deleteWorker()"
                            >Delete</v-btn>
                            <v-btn
                                size="small"
                                color="green-darken-1"
                                @click="deleteDialog = false"
                            >Keep</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-btn>
                  </template>
                </v-data-table>
                <v-data-table
                    v-show="EWvisible"
                    items-per-page="5"
                    :items-per-page-options="[5]"
                    :headers="headers4"
                    :items="eworkers"
                    :search="search_e"
                    class="elevation-1"
                    item-value="name"
                    density="compact"
                >
                  <template v-slot:top>
                    <v-toolbar color="white" flat>
                      <div class="d-flex w-100 align-center">
                        <v-text-field
                            v-model="search_e"
                            append-icon="mdi-magnify"
                            density="compact"
                            hide-details="true"
                            label="Search"
                            single-line variant="outlined">
                        </v-text-field>
                        <v-btn
                            size="small"
                            class="ml-2"
                            color="blue-darken-1"
                            variant="elevated"
                            @click="addNewEworker">
                          Add worker
                        </v-btn>
                      </div>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.deployed="{ item }">
                    <v-badge inline color="red"> </v-badge>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                        class="mr-2 my-1"
                        size="small"
                        color="orange-accent-1"
                        variant="elevated"
                        @click="viewEditE(item.worker_id)"
                    >View and Edit
                    </v-btn>
                    <v-btn
                        size="small"
                        color="red-darken-1"
                        @click="deleteItemE = item"
                    >
                      Delete
                      <v-dialog
                          v-model="deleteEDialog"
                          activator="parent"
                          width="auto"
                      >
                        <v-card>
                          <v-card-text>
                            Worker {{ deleteItemE.name }} will be deleted
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                                size="small"
                                color="red-accent-2"
                                @click="deleteEDialog = false;deleteWorkerE()"
                            >Delete</v-btn>
                            <v-btn
                                size="small"
                                color="green-darken-1"
                                @click="deleteEDialog = false"
                            >Keep</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-btn>
                  </template>
                </v-data-table>
                <br/>
                <v-expansion-panels
                    v-if="DWvisible"
                    v-model="panel"
                    variant="accordion"
                    size="compact"
                >
                  <v-expansion-panel
                      title="Personal Data"
                      value="personal"
                  >
                    <v-expansion-panel-text>
                      <v-row>
                        <v-col
                            cols="12"
                        >
                          <v-text-field
                              v-model="editedItem.name"
                              density="compact"
                              label="Name"
                              placeholder="John Doe"
                              variant="outlined"
                              @change="markChanged"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                            class="v-col-md-6"
                            cols="12"
                        >
                          <v-text-field
                              v-model="editedItem.phone"
                              density="compact"
                              label="Mobile phone #"
                              placeholder="xxxyyyzzzz"
                              variant="outlined"
                              @change="markChanged"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                            class="v-col-md-6"
                            cols="12"
                        >
                          <v-select
                              v-model="editedItem.agency"
                              :items="agencies"
                              density="compact"
                              item-props="false"
                              item-title="name"
                              item-value="id"
                              label="Worker Agency"
                              variant="outlined"
                              @update:model-value="markChanged"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                              size="small"
                              :disabled="isUnchanged()"
                              color="blue-darken-1"
                              variant="elevated"
                              @click="saveUpdates"
                          >
                            Update
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                  <v-expansion-panel
                      title="Dosimetry record"
                      value="readings"
                  >
                    <v-expansion-panel-text>
                      <v-row>
                        <v-col>
                          Cumulative value for the last 365 days is <span
                            class="font-weight-black">{{ editedItem.sum366 }}</span>mrem
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-data-table
                              v-model:items-per-page="itemsPerPageRec"
                              :headers="headers2"
                              :items="editedItem.readings"
                              class="elevation-1"
                              item-value=""
                              density="compact"
                          >
                            <template v-slot:item.date="{item}">
                              <span>{{ item.date.substring(4, 6) + "-" + item.date.substring(6, 8) + "-" + item.date.substring(0, 4) }}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-btn
                                  size="small"
                                  color="red-darken-1"
                                  @click="deleteRecordItem = item"
                              >
                                Delete
                                <v-dialog
                                    v-model="deleteRecordDialog"
                                    activator="parent"
                                    width="auto"
                                >
                                  <v-card>
                                    <v-card-text>
                                      Record for event {{ deleteRecordItem.event }} / mission
                                      {{ deleteRecordItem.description }} will be deleted
                                    </v-card-text>
                                    <v-card-actions>
                                      <v-btn color="red-accent-2" @click="deleteRecordDialog = false;deleteRecord()">
                                        Delete
                                      </v-btn>
                                      <v-btn color="green-darken-1" @click="deleteRecordDialog = false">Keep</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                  <v-expansion-panel
                      title="Last mission record"
                      value="daily"
                  >
                    <v-expansion-panel-text>
                      <v-row>
                        <v-col>
                          <v-data-table
                              density="compact"
                              v-model:items-per-page="itemsPerPageRec"
                              :headers="headers3"
                              :items="editedItem.daily.values"
                              class="elevation-1"
                              item-value=""
                          >
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels
                    v-if="EWvisible"
                    v-model="panelE"
                    variant="accordion"
                    size="compact"
                >
                  <v-expansion-panel
                      title="Personal Data"
                      value="personal"
                  >
                    <v-expansion-panel-text>
                      <v-row>
                        <v-col
                            cols="12"
                        >
                          <v-text-field
                              v-model="editedItemE.name"
                              density="compact"
                              label="Name"
                              placeholder="John Doe"
                              variant="outlined"
                              @change="markChangedE"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                            class="v-col-md-6"
                            cols="12"
                        >
                          <v-text-field
                              v-model="editedItemE.phone"
                              density="compact"
                              label="Mobile phone #"
                              placeholder="xxxyyyzzzz"
                              variant="outlined"
                              @change="markChangedE"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                            class="v-col-md-6"
                            cols="12"
                        >
                          <v-select
                              v-model="editedItemE.agency"
                              :items="agencies"
                              density="compact"
                              item-props="false"
                              item-title="name"
                              item-value="id"
                              label="Worker Agency"
                              variant="outlined"
                              @update:model-value="markChangedE"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                              size="small"
                              :disabled="isUnchangedE()"
                              color="blue-darken-1"
                              variant="elevated"
                              @click="saveUpdatesE"
                          >
                            Update
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                  <v-expansion-panel
                      title="Last mission record"
                      value="daily"
                  >
                    <v-expansion-panel-text>
                      <v-row>
                        <v-col>
                          <v-row><v-col>Mission name: {{editedItemE.missions.name}}</v-col></v-row>
                          <v-data-table
                              density="compact"
                              v-model:items-per-page="itemsPerPageRec"
                              :headers="headers3"
                              :items="editedItemE.missions.tasks"
                              class="elevation-1"
                              item-value=""
                          >
                            <template v-slot:item.time="{ item }">
                              {{timestampToStr(item.time)}}
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <div id="wlayout">
              <mapwrk/>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
            variant="outlined"
            @click="dialog=false"
        >Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {defaultEmWorkerItem, defaultWorkerItem} from "@/objTemplates.js";

export default {
  created() {
    this.today = this.getTodayString();
    this.period_start = this.get366back();
    this.readworkers();
    this.readeworkers();
    this.readAgencies();
  },
  methods: {
    timestampToStr(ts) {
      let dt = new Date();
      dt.setTime(ts);
      return dt.toLocaleString('en-US')
    },
    showDW() {
      this.DWvisible=true;
      this.EWvisible=false;
    },
    showEW() {
      this.DWvisible=false;
      this.EWvisible=true;
    },
    markChanged() {
      console.log("change")
      this.unchangedData = false;
      console.log(JSON.stringify(this.editedItem))
    },
    markChangedE() {
      console.log("change")
      this.unchangedDataE = false;
      console.log(JSON.stringify(this.editedItemE))
    },
    isUnchanged() {
      return this.unchangedData;
    },
    isUnchangedE() {
      return this.unchangedDataE;
    },
    viewEdit(id) {
      let y = this.find_worker_data(id);
      console.log(y);
      this.unchangedData = true;
      this.editedItem = Object.assign(
          {},
          {
            'worker_id': id, 'name': y.name, 'agency': y.agency, 'phone': y.phone,
            'readings': y.readings, 'sum366': y.sum366, 'daily': y.daily
          }
      );
      this.editedIndex = id;
      this.panel = 'personal';
    },
    viewEditE(id) {
      let y = this.find_eworker_data(id);
      this.unchangedDataE = true;
      this.editedItemE = Object.assign(
          {},
          {
            'worker_id': id, 'name': y.name, 'agency': y.agency, 'phone': y.phone
          }
      );
      let mission= {};
      if (y.missions) {
        mission = y.missions.find(mission => !mission.completed) || {} ;
      }
      this.editedItemE.missions = mission;
      this.editedIndexE = id;
      this.panelE = 'personal';
    },
    saveUpdates() {
      this.unchangedData = true;
      if (this.new_ids.indexOf(this.editedIndex) < 0) {
        this.updateWorker();
      } else {
        this.saveNewWorker();
      }
    },
    saveUpdatesE() {
      this.unchangedDataE = true;
      if (this.new_eids.indexOf(this.editedIndexE) < 0) {
        this.updateWorkerE();
      } else {
        this.saveNewWorkerE();
      }
    },
    async deleteRecord() {
      // ToDo
      let delidx = this.editedItem.readings.indexOf(this.deleteRecordItem);
      this.editedItem.readings.splice(delidx, 1);
      let check = this.validateReadings(this.editedItem.readings, this.editedItem.sum366);
      console.log(JSON.stringify(check))
      if (check.updateNeeded) {
        this.editedItem.sum366 = check.sum;
      }
      this.updateWorker();
    },
    async deleteWorker() {
      let dref = this.workers_ref[this.deleteItem.worker_id];
      await deleteDoc(dref);
      delete (this.workers_ref[this.deleteItem.worker_id]);
      for (let i = 0; i < this.workers.length; i++) {
        if (this.workers[i].worker_id === this.deleteItem.worker_id) {
          this.workers.splice(i, 1);
          break;
        }
      }
    },
    async deleteWorkerE() {
      let dref = this.eworkers_ref[this.deleteItemE.worker_id];
      await deleteDoc(dref);
      delete (this.eworkers_ref[this.deleteItemE.worker_id]);
      for (let i = 0; i < this.eworkers.length; i++) {
        if (this.eworkers[i].worker_id === this.deleteItemE.worker_id) {
          this.eworkers.splice(i, 1);
          break;
        }
      }
    },
    async saveNewWorker() {
      let wdata = {'sum366': 0, 'today': 0, 'daily': {'values': [], 'date': ''}, 'readings': []};
      Object.keys(this.editedItem).forEach((k) => {
        wdata[k] = this.editedItem[k]
      });
      wdata = JSON.parse(JSON.stringify(wdata));
      wdata.readings = JSON.stringify(wdata.readings);
      wdata.daily = JSON.stringify(wdata.daily);
      let newDocRef = await addDoc(this.fbCollection, wdata);
      this.workers_ref[this.editedIndex] = newDocRef;
      let idx = this.new_ids.indexOf(this.editedIndex);
      if (idx > -1) {
        this.new_ids.splice(idx, 1);
      }
    },
    async saveNewWorkerE() {
      let wdata = {} ;
      Object.keys(this.editedItemE).forEach((k) => {
        wdata[k] = this.editedItemE[k]
      });
      wdata = JSON.parse(JSON.stringify(wdata));
      let newDocRef = await addDoc(this.fbCollectionE, wdata);
      this.eworkers_ref[this.editedIndexE] = newDocRef;
      let idx = this.new_eids.indexOf(this.editedIndexE);
      if (idx > -1) {
        this.new_eids.splice(idx, 1);
      }
    },
    updateWorker() {
      let wdata = this.find_worker_data(this.editedIndex);
      Object.keys(this.editedItem).forEach((k) => {
        wdata[k] = this.editedItem[k]
      });
      this.ids_to_update.push(this.editedIndex);
      this.update_workers();
    },
    updateWorkerE() {
      let wdata = this.find_eworker_data(this.editedIndexE);
      Object.keys(this.editedItemE).forEach((k) => {
        wdata[k] = this.editedItemE[k]
      });
      this.eids_to_update.push(this.editedIndexE);
      this.update_eworkers();
    },
    async addNew() {
      const addObj = structuredClone(defaultWorkerItem);
      addObj.worker_id = this.create_new_id();
      addObj.readings = JSON.stringify(addObj.readings);
      addObj.daily = JSON.stringify(addObj.daily);
      this.workers.unshift(addObj);
      this.workers_ref[addObj.worker_id] = await addDoc(this.fbCollection, addObj);
    },
    async addNewEworker() {
      const addObj = structuredClone(defaultEmWorkerItem);
      addObj.worker_id = this.create_new_id();
      this.eworkers.unshift(addObj);
      this.eworkers_ref[addObj.worker_id] = await addDoc(this.fbCollectionE, addObj);
    },
    getTodayString() {
      const today = new Date();
      const yr = String(today.getFullYear()).padStart(4, '0');
      const mo = String(today.getMonth() + 1).padStart(2, '0');
      const da = String(today.getDate()).padStart(2, '0');
      return `${yr}-${mo}-${da}`;
    },
    get366back() {
      const today = new Date();
      const today_1y_ago = new Date();
      today_1y_ago.setDate(today.getDate() - 366);
      const yr = String(today_1y_ago.getFullYear()).padStart(4, '0');
      const mo = String(today_1y_ago.getMonth() + 1).padStart(2, '0');
      const da = String(today_1y_ago.getDate()).padStart(2, '0');
      return `${yr}-${mo}-${da}`;
    },
    validateReadings(rlist, sumv) {
      let sum = 0;
      let changes = false;
      let newlist = [];
      rlist.forEach((r) => {
        if (r.date > this.period_start) {
          newlist.push(r);
          sum += r.value;
        } else {
          changes = true;
        }
      });
      if (sum !== sumv) {
        changes = true;
      }
      return {updateNeeded: changes, sum: sum, readings: newlist};
    },
    update_workers() {
      for (let i = 0; i < this.ids_to_update.length; i++) {
        this.update_worker(this.ids_to_update[i]);
      }
      this.ids_to_update = [];
    },
    update_eworkers() {
      for (let i = 0; i < this.eids_to_update.length; i++) {
        this.update_eworker(this.eids_to_update[i]);
      }
      this.eids_to_update = [];
    },
    async update_eworker(wid) {
      let wdata = this.find_eworker_data(wid);
      let wref = this.eworkers_ref[wid];
      if (wdata) {
        await setDoc(wref, wdata);
      }
    },
    async update_worker(wid) {
      let wdata = this.find_worker_data(wid);
      let wref = this.workers_ref[wid];
      if (wdata) {
        wdata = JSON.parse(JSON.stringify(wdata));
        wdata.readings = JSON.stringify(wdata.readings);
        wdata.daily = JSON.stringify(wdata.daily);
        await setDoc(wref, wdata);
      }
    },
    find_worker_data(wid) {
      let wdata = null;
      for (let j = 0; j < this.workers.length; j++) {
        if (this.workers[j].worker_id === wid) {
          wdata = this.workers[j];
        }
      }
      return wdata;
    },
    find_eworker_data(ewid) {
      let wdata = null;
      for (let j = 0; j < this.eworkers.length; j++) {
        if (this.eworkers[j].worker_id === ewid) {
          wdata = this.eworkers[j];
        }
      }
      return wdata;
    },
    create_new_id() {
      return Date.now();
    },
    async readAgencies() {
      this.agCollection = collection(db, 'agencies');
      const q = query(this.agCollection);
      const agSnap = await getDocs(q);
      this.agencies = [];
      agSnap.forEach((ag) => {
        this.agencies.push(ag.data().name);
      })
      console.log(JSON.stringify(this.agencies))
    },
    async readeworkers() {
      this.fbCollectionE = collection(db, 'eworkers');
      const q = query(this.fbCollectionE);
      const eworkersSnap = await getDocs(q);
      let update_eworker = false;
      this.eids_to_update = [];
      eworkersSnap.forEach((ew) => {
        const ref = ew.ref;
        const wdata = ew.data();
        const eworker = {...wdata};
        if (!wdata.worker_id) {
          eworker.worker_id = this.create_new_id();
          update_eworker = true;
        } else {
          eworker.worker_id = wdata.worker_id;
        }
        this.eworkers_ref[eworker.worker_id] = ref;
        if (update_eworker) {
          this.eids_to_update.push(eworker.worker_id)
        }
        this.eworkers.push(eworker);
      })
      console.log(JSON.stringify(this.eworkers));
      this.update_eworkers();
    },
    async readworkers() {
      this.fbCollection = collection(db, 'dworkers');
      const q = query(this.fbCollection);
      const workersSnap = await getDocs(q);
      let update_worker = false;
      this.ids_to_update = [];
      workersSnap.forEach((w) => {
        const ref = w.ref;
        const wdata = w.data();
        const worker = {};
        update_worker = false;
        if (!wdata.worker_id) {
          worker.worker_id = this.create_new_id();
          update_worker = true;
        } else {
          worker.worker_id = wdata.worker_id;
        }
        this.workers_ref[worker.worker_id] = ref;
        if (wdata.sum366) {
          worker.sum366 = wdata.sum366;
        } else {
          worker.sum366 = 0;
          update_worker = true;
        }
        if (wdata.today) {
          worker.today = wdata.today;
        } else {
          worker.today = 0;
          update_worker = true;
        }
        if (!wdata.sum366) {
          worker.sum366 = 0;
        } else {
          worker.sum366 = wdata.sum366;
        }
        if (wdata.readings) {
          worker.readings = JSON.parse(wdata.readings);
          let check = this.validateReadings(worker.readings, worker.sum366);
          if (check.updateNeeded) {
            worker.readings = check.readings;
            worker.sum366 = check.sum;
            wdata.readings = JSON.stringify(check.readings);
            wdata.sum = check.sum;
            update_worker = true;
          }
        } else {
          worker.readings = [];
          update_worker = true;
        }
        if (wdata.daily) {
          wdata.daily = JSON.parse(wdata.daily);
          if (wdata.daily.date && wdata.daily.values.length > 0 && wdata.daily.date < this.today) {
            wdata.daily.values = [];
            update_worker = true;
            worker.today = 0;
          }
        } else {
          wdata.daily = {values:[]};
          update_worker = true;
        }
        worker.daily = wdata.daily;
        if (update_worker) {
          this.ids_to_update.push(worker.worker_id)
        }
        worker.name = wdata.name;
        worker.phone = wdata.phone;
        worker.agency = wdata.agency;
        this.workers.push(worker);
      });
      this.update_workers();
    }
  },
  data: () => ({
    dialog: false,
    EWvisible:false,
    DWvisible:true,
    search_d: '',
    search_e: '',
    panel: '',
    panelE: '',
    unchangedData: true,
    unchangedDataE: true,
    editedIndex: -1,
    editedIndexE: -1,
    confirmDelete: true,
    confirmDeleteE: true,
    deleteEDialog: false,
    deleteDialog: false,
    deleteRecordDialog: false,
    deleteItem: null,
    deleteItemE: null,
    deleteRecordItem: null,
    editedItem: {
      worker_id: 0,
      phone: '',
      name: '',
      agency: ''
    },
    editedItemE: {
      worker_id: 0,
      phone: '',
      name: '',
      agency: ''
    },
    new_ids: [],
    new_eids: [],
    readings: false,
    drawer: null,
    itemsPerPage: 5,
    itemsPerPageRec: null,
    headers1: [
      {
        title: 'Name',
        align: 'start',
        key: 'name',
        value: 'name',
        sortable: true
      },
      {
        title: 'Phone',
        align: 'start',
        key: 'phone',
        value: 'phone',
        sortable: false
      },
      {
        title: 'Agency',
        align: 'start',
        key: 'agency',
        value: 'agency',
        sortable: true
      },
      {
        title: '',
        align: 'start',
        key: 'actions',
        value: 'actions',
        sortable: false
      }
    ],
    headers2: [
      {
        title: 'Date',
        align: 'start',
        key: 'date',
        value: 'date',
        sortable: true
      },
      {
        title: 'Mission',
        align: 'start',
        key: 'description',
        value: 'description',
        sortable: false
      },
      {
        title: 'Event',
        align: 'start',
        key: 'event',
        value: 'event',
        sortable: true
      },
      {
        title: 'Radioactive Dose',
        align: 'start',
        key: 'value',
        value: 'value',
        sortable: true
      },
      {
        title: '',
        align: 'start',
        key: 'actions',
        value: 'actions',
        sortable: false
      }
    ],
    headers3: [
      {
        title: 'Date/Time',
        align: 'start',
        key: 'time',
        value: 'time',
        sortable: false
      },
      {
        title: 'Reading',
        align: 'start',
        key: 'value',
        value: 'value',
        sortable: false
      }
    ],
    headers4: [
      {
        title: 'Name',
        align: 'start',
        key: 'name',
        value: 'name',
        sortable: true
      },
      {
        title: 'Phone',
        align: 'start',
        key: 'phone',
        value: 'phone',
        sortable: false
      },
      // {
      //   title: 'Agency',
      //   align: 'start',
      //   key: 'agency',
      //   value: 'agency',
      //   sortable: true
      // },
      {
        title: 'Deployed',
        align: 'start',
        key: 'deployed',
        value: 'deployed',
        sortable: true
      },
      {
        title: '',
        align: 'start',
        key: 'actions',
        value: 'actions',
        sortable: false
      }
    ],
    period_start: null,
    wlist: [],
    ewlist: [],
    today: null,
    workers_ref: {},
    eworkers_ref: {},
    ids_to_update: [],
    eids_to_update: [],
    workers: [],
    eworkers: [],
    agencies: [],
    fbCollection: null,
    fbCollectionE: null,
    agCollection: null
  }),
  computed: {
    dlgwid() {
      if (this.$vuetify.display.name === 'xs') {
        return '90vw';
      } else if (this.$vuetify.display.name === 'sm') {
        return '90vw';
      } else if (this.$vuetify.display.name === 'md') {
        return '90vw';
      } else if (this.$vuetify.display.name === 'lg') {
        return '90vw';
      } else {
        return '90vw';
      }
    }
  }
}
</script>
<style scoped>
header {
  line-height: 1.5;
}
#wlayout {
  flex: 1;
  display: flex;
  position: relative;
  width: 100%;
  height: calc(90vh - 64px - 56px);
  margin-bottom: 0;
}
.full-width-content {
  max-width: 100%;
  margin: 0 auto;
  /* Add any additional styling you need */
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }
}
</style>
